import { CallToAction, RichText } from '@sprinklr/shared-lib';
import React, { ReactElement, useMemo } from 'react';
import { Box, Flex, Heading } from 'theme-ui';
import { Link } from '@sprinklr/shared-lib/components/link';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import { useTranslation } from 'react-i18next';
import { PodcastV1 } from '../types';

type Props = Pick<
  PodcastV1,
  'episodeNumber' | 'title' | 'description' | 'externalLinks'
>;

const preparePodcastCta = ({ channel, externalUrl: url }, t) => {
  const baseCta: any = {
    id: 'cxwise-podcast',
    action: 'NAVIGATE_TO_URL',
    variant: 'darkPrimary',
    external: true,
    ctaSx: {
      fontSize: '10px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      '&:hover': {},
    },
    iconSx: {
      path: {
        fill: '#fff',
      },
      width: '18px',
    },
    icon: channel === 'YouTube' ? t('YOUTUBE') : t('PLAY'),
    text: channel === 'YouTube' ? t('WATCH VIDEO') : t('LISTEN PODCAST'),
    url,
  };

  return baseCta;
};

const PodcastContent = (props: Props): ReactElement => {
  const { episodeNumber, title, description, externalLinks = [] } = props;
  const { t } = useTranslation();

  const { links, podcastCta } = useMemo(() => {
    const [ctaItem, ...links] = [...externalLinks];
    return {
      links,
      podcastCta: preparePodcastCta(ctaItem, t),
    };
  }, [externalLinks, t]);

  return (
    <Flex
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '2px',
        maxWidth: [`${250 + 20}px`, '420px'],
        padding: [
          '40px 0px 150px 20px',
          '20px 0px 60px 0px',
          '90px 90px 90px 0px',
        ],
        color: '#000',
      }}
    >
      <Heading
        sx={{
          fontFamily: 'inherit',
          fontWeight: 700,
          fontSize: '12px',
          lineHeight: '15px',
          letterSpacing: '2.5px',
          textTransform: 'uppercase',
        }}
        as="h3"
      >
        Episode {episodeNumber}
      </Heading>

      <Heading
        as="div"
        sx={{
          fontFamily: 'inherit',
          fontWeight: 800,
          fontSize: ['32px', '30px'],
          lineHeight: '36px',
          paddingBottom: ['10px', '15px'],
        }}
      >
        {title}
      </Heading>

      <Box
        sx={{
          ul: {
            paddingLeft: '10px',
            lineHeight: 1.2,
            'li::marker': {
              fontSize: '10px',
            },
          },
          p: {
            fontSize: '14px',
            lineHeight: '17px',
            fontWeight: 500,
          },
        }}
      >
        <RichText richTextObject={description} />
      </Box>

      <Box
        sx={{
          paddingY: ['10px', '20px'],
          button: {
            padding: ['6px 60px', '8px 34px'],
          },
        }}
      >
        <CallToAction {...podcastCta} />
      </Box>

      <Flex
        sx={{
          gap: ['20px', '30px'],
          marginTop: '10px',
        }}
      >
        {links.map(link => (
          <Link
            key={link.linkName}
            href={link.externalUrl}
            external
            isOpenNewTab
          >
            <Icon iconName={link.channel} />
          </Link>
        ))}
      </Flex>
    </Flex>
  );
};

export default PodcastContent;
