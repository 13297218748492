import { GatsbyImage } from 'gatsby-plugin-image';
import React, { ReactElement } from 'react';
import { Box, ThemeUICSSObject } from 'theme-ui';

type Props = {
  images: any[];
  videos: any[];
  mediaSx?: ThemeUICSSObject;
};

const MediaBackground = ({
  images = [],
  videos = [],
  mediaSx = {},
}: Props): ReactElement => {
  const imgData = images[0] || {};
  const videoData = videos[0] || {};
  const mediaType = videos[0] ? 'VIDEO' : 'IMAGE';

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: '-2',
        ...mediaSx,
      }}
    >
      {mediaType === 'VIDEO' ? (
        <video
          autoPlay={true}
          muted
          loop
          height="100%"
          width="100%"
          style={{ objectFit: 'cover' }}
          poster={imgData?.file?.url}
        >
          <source src={videoData.file?.url} type="video/mp4"></source>
          <img src={imgData?.file?.url}></img>
        </video>
      ) : (
        <GatsbyImage
          image={imgData.gatsbyImageData}
          alt={imgData.title}
          style={{ width: '100%', height: '100%' }}
        />
      )}
    </Box>
  );
};

export default MediaBackground;
