import { Box, Flex, ThemeUICSSObject } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

const AUTHOR_CONTAINER_STYLES: ThemeUICSSObject = {
  position: 'absolute',
  alignItems: 'center',
  right: '60px',
  bottom: [0, '52px'],
  transform: ['initial', 'translateY(50%)'],
  pointerEvents: 'none',
  gap: '10px',
};

const AUTHOR_TEXT_CONTAINER_STYLES: ThemeUICSSObject = {
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 500,
  fontSize: ['12px', '14px'],
  lineHeight: ['14px', '16px'],
  letterSpacing: ['.5px', '1px'],
  flexShrink: 0,
  maxWidth: ['170px', '220px'],
  textAlign: 'right',
  gap: '5px',
};

const AUTHOR_IMAGE_CONTAINER_STYLES: ThemeUICSSObject = {
  height: ['120px', '160px'],
  width: ['120px', '160px'],
  flexShrink: 0,
};

const AUTHOR_IMAGE_STYLES: ThemeUICSSObject = {
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  borderRadius: '100%',
};

const Author = ({ title, description, authorImages, authorSx }) => (
  <Flex sx={authorSx ?? AUTHOR_CONTAINER_STYLES}>
    <Box sx={AUTHOR_TEXT_CONTAINER_STYLES}>
      <Box>{title}</Box>
      <Box
        sx={{
          fontWeight: 800,
        }}
      >
        {description}
      </Box>
    </Box>
    <Box sx={AUTHOR_IMAGE_CONTAINER_STYLES}>
      <GatsbyImage
        image={authorImages[0].gatsbyImageData}
        alt={authorImages[0]?.title}
        style={AUTHOR_IMAGE_STYLES}
      />
    </Box>
  </Flex>
);

export { Author };
