import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import React, { useCallback, useState } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { Box } from 'theme-ui';
import SprinklrPodcast from './components/SprinklrPodcast';
import { ContainerPaddingValue } from '@sprinklr/shared-lib/@types/entities';
import { PodcastV1 } from './types';

const SprinklrPodcastV1Template = (props: {
  topMargin: ContainerPaddingValue | null;
  bottomMargin: ContainerPaddingValue | null;
  list: PodcastV1[];
}): ReactElement => {
  const { topMargin, bottomMargin, list } = props;
  const padding = {
    top: topMargin ?? 'X-SMALL',
    bottom: bottomMargin ?? 'NONE',
  };
  const [pt, pb] = useContainerPadding(padding);

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const currProps = list[currentIndex];

  const showNextPodcast = useCallback(() => {
    setCurrentIndex(prev => (prev + 1) % list.length);
  }, [setCurrentIndex, list]);

  return (
    <Box sx={{ pt, pb }}>
      <SprinklrPodcast
        {...currProps}
        onClick={showNextPodcast}
        showArrow={list.length > 1}
      />
    </Box>
  );
};

export default SprinklrPodcastV1Template;
