import React, { ReactElement } from 'react';
import useMeasure from 'react-use/lib/useMeasure';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { RichText } from '@sprinklr/shared-lib';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import MediaBackground from './MediaBackground';
import PodcastContent from './PodcastContent';

import { Author } from './Author';
import { QuotationMarks } from './QuotationMarks';
import { PodcastV1 } from '../types';

const ToggleArrow = ({ onClick }) => (
  <Box
    sx={{
      width: '64px',
      height: '64px',
      background: '#f1f1f1',
      boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.12)',
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      cursor: 'pointer',
      top: '50%',
      left: '100%',
      transform: 'translate(-50%, -50%)',
    }}
    onClick={onClick}
  >
    <Icon iconName="arrowRight" />
  </Box>
);

const BaseCircle = ({
  measureRef,
  diameter,
  height,
  width,
  topSx,
  sx,
  childrenSx,
  bottomEl,
  children,
  ...attr
}) => (
  <Box
    {...attr}
    data-diameter={diameter}
    data-height={height}
    sx={{
      ...sx,
      height: diameter ? `${diameter}px` : undefined,
      width: diameter ? `${diameter}px` : undefined,
      borderRadius: '50%',
      background: 'linear-gradient(145.34deg, #00BAE9 28.96%, #70BF54 102.81%)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    }}
  >
    <Box sx={topSx}>
      <QuotationMarks />
    </Box>
    <Box
      sx={height ? { height: `${height}px`, width: `${width}px` } : undefined}
    >
      <Box ref={measureRef} style={{ ...childrenSx, display: 'flex' }}>
        {children}
      </Box>
    </Box>
    {bottomEl}
  </Box>
);

const DesktopCircle = ({ children, bottomEl, sx }) => {
  const [ref, { height, width }] = useMeasure();
  const diameter = Math.sqrt(height * height + width * width);

  return (
    <BaseCircle
      diameter={diameter}
      height={height}
      width={width}
      sx={{ ...sx, transform: 'translateX(5%)' }}
      topSx={{
        position: 'absolute',
        top: '50px',
        left: `${(diameter - width) / 2}px`,
      }}
      measureRef={ref}
      bottomEl={bottomEl}
    >
      {children}
    </BaseCircle>
  );
};

const MobileCircle = ({ children, bottomEl, sx }) => {
  const [ref, { height, width }] = useMeasure();
  const h = height;
  const w = width * 2;
  const diameter = Math.sqrt(h * h + w * w);

  return (
    <BaseCircle
      diameter={diameter}
      height={height}
      width={width}
      sx={{ ...sx, transform: 'translateX(-55%)' }}
      childrenSx={{ transform: 'translate(65%,-5%)' }}
      topSx={{
        position: 'absolute',
        top: '0px',
        right: '200px',
      }}
      bottomEl={bottomEl}
      measureRef={ref}
    >
      {children}
    </BaseCircle>
  );
};

const MEDIA_SX: ThemeUIStyleObject = {
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
};

const AUTHOR_BASE_STYLES: ThemeUIStyleObject = {
  position: 'absolute',
  alignItems: 'center',
  right: '60px',
  bottom: [0, '52px'],
  pointerEvents: 'none',
  gap: ['10px', '15px'],
};

const AUTHOR_STYLES: ThemeUIStyleObject[] = [
  {
    ...AUTHOR_BASE_STYLES,
    transform: ['translate(-5%,-35%)', 'translate(0%,25%)'],
  },
  {
    ...AUTHOR_BASE_STYLES,
    transform: ['translate(20%,-135%)', 'translate(25%,-60%)'],
  },
];

const SprinklrPodcast = (
  props: PodcastV1 & {
    onClick: () => void;
    showArrow: boolean;
  },
): ReactElement => {
  const {
    images,
    videos,
    promotionText,
    onClick,
    authors,
    showArrow,
    ...rest
  } = props;
  const isMobile = useBreakpointIndex({ defaultIndex: 3 }) === 0;
  const Circle = isMobile ? MobileCircle : DesktopCircle;

  return (
    <Box
      sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}
    >
      <MediaBackground images={images} videos={videos} mediaSx={MEDIA_SX} />
      <Box
        sx={{
          width: '100%',
          paddingTop: '52px',
          display: 'flex',
          alignItems: 'center',
          flex: '0 0 auto',
        }}
      >
        <Circle
          sx={{ flex: '0 0 auto' }}
          bottomEl={
            <>
              {authors.map((author, index) => {
                const {
                  title: authorTitle,
                  description: authorDescription,
                  images: authorImages,
                } = author;
                return (
                  <Author
                    key={index}
                    title={authorTitle}
                    description={authorDescription}
                    authorImages={authorImages}
                    authorSx={AUTHOR_STYLES[index]}
                  />
                );
              })}
              {showArrow ? <ToggleArrow onClick={onClick} /> : null}
            </>
          }
        >
          <PodcastContent {...rest} />
        </Circle>
      </Box>

      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Box
          sx={{
            p: {
              color: '#fff',
              lineHeight: '30px',
              fontSize: ['18px', null, '24px'],
              fontWeight: 700,
              paddingRight: ['0', '20px'],
              paddingBlock: ['32px 16px', '72px 36px'],
              textAlign: ['center', 'right'],
              margin: 0,
            },
          }}
        >
          <RichText richTextObject={promotionText} />
        </Box>
      </Box>
    </Box>
  );
};

export default SprinklrPodcast;
